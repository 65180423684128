import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import { IResponse, IResponseFull, ISurveyFilterToApply } from '@/interfaces/survey'
import api from '@/api'
import store from '@/store'
import mainModule from '@/store/main'
import Vue from 'vue'

@Module
export class ResponseModule extends VuexModule {
  // items[surveyId] = IResponseFull[]
  items: { [key: string]: IResponseFull[] } = {}

  itemsBySurveyId(surveyId: string): IResponseFull[] {
    return Object.values(this.items[surveyId] || {})
  }

  // itemById(surveyId: string, responseId: string): IResponseFull | null {
  //   return this.items[surveyId]?.[responseId] || null
  // }

  @Mutation
  setItems(payload: { surveyId: string; items: IResponseFull[] }) {
    Vue.set(this.items, payload.surveyId, payload.items)
    // data.forEach((item) => {
    //   if (!this.items[surveyId]) {
    //     Vue.set(this.items, surveyId, {})
    //   }
    //   Vue.set(this.items[surveyId], item.id, item)
    // })
  }

  @Mutation
  deleteItem(payload: { surveyId: string; id: string }): void {
    this.items[payload.surveyId] = this.items[payload.surveyId].filter(
      (item: IResponseFull) => item.id !== payload.id
    )
  }

  @Action
  async actionGetResponses({
    surveyId,
    filters = []
  }: {
    surveyId: string
    filters: ISurveyFilterToApply[]
  }): Promise<void> {
    try {
      const response = await api.survey.getResponses(mainModule.token, surveyId, filters)
      if (response) {
        this.setItems({ surveyId, items: response.data })
      }
    } catch (error) {
      console.error('Request error: ', error)
    }
  }

  @Action
  async actionGetResponseById({
    surveyId,
    responseId
  }: {
    surveyId: string
    responseId: string
  }): Promise<IResponseFull | undefined> {
    try {
      const response = await api.survey.getResponseById(mainModule.token, surveyId, responseId)
      if (response) {
        return response.data
      }
    } catch (error) {
      console.error('Request error: ', error)
    }
  }

  @Action
  async actionSendResponse({
    surveyId,
    responseId,
    responseData,
    isComplete = false
  }: {
    surveyId: string
    responseId: string
    responseData: IResponse
    isComplete?: boolean
  }): Promise<void> {
    try {
      await api.survey.sendResponse({
        id: responseId,
        survey_id: surveyId,
        data: responseData,
        is_completed: isComplete
      })
    } catch (error) {
      console.error('Request error: ', error)
    }
  }

  @Action
  async actionDelete(payload: IResponseFull): Promise<void> {
    try {
      const loadingNotification = {
        content: 'saving',
        showProgress: true
      }
      mainModule.addNotification(loadingNotification)
      const response = (
        await Promise.all([
          api.survey.deleteResponseById(mainModule.token, payload.survey_id, payload.id!),
          await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500))
        ])
      )[0]

      this.deleteItem({ surveyId: payload.survey_id, id: payload.id! })
      mainModule.removeNotification(loadingNotification)
      mainModule.addNotification({
        content: 'Response successfully deleted',
        color: 'success'
      })
    } catch (error) {
      await mainModule.actionCheckApiError(error)
    }
  }
}

export const responseModule = new ResponseModule({ store, name: 'response' })
export default responseModule
