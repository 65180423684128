
import { Component, Vue } from 'vue-property-decorator'
import { ISurvey } from '@/interfaces/survey'

import { Model } from 'survey-vue'
import * as SurveyAnalyticsTabulator from 'survey-analytics/survey.analytics.tabulator'

import 'survey-analytics/survey.analytics.css'
import 'tabulator-tables/dist/css/tabulator.css'
import 'survey-analytics/survey.analytics.tabulator.css'

import surveyModule from '@/store/survey'
import responseModule from '@/store/response'

@Component
export default class SurveyTable extends Vue {
  mounted() {
    const survey = new Model(this.survey.data_json)

    const visPanel = new SurveyAnalyticsTabulator.Tabulator(survey, this.items, {
      downloadButtons: ['csv'],
      haveCommercialLicense: true
    })

    visPanel.render(this.$refs.container as any)
  }

  get survey(): ISurvey {
    return surveyModule.itemById(this.$router.currentRoute.params.surveyId)
  }

  get items(): { [key: string]: any }[] {
    return responseModule.itemsBySurveyId(this.survey.id!).map((item) => item.data)
  }
}
