
import { Component, Vue, Watch } from 'vue-property-decorator'
import { ISurvey } from '@/interfaces/survey'
import * as Survey from 'survey-vue'

import * as SurveyAnalytics from 'survey-analytics'
import 'survey-analytics/survey.analytics.css'

import surveyModule from '@/store/survey'
import responseModule from '@/store/response'

@Component
export default class SurveyCharts extends Vue {
  visPanel: SurveyAnalytics.VisualizationPanel | null = null

  mounted() {
    this.renderChart()
  }

  range = function (count: number, start_with = 0): number[] {
    return [...Array(count).keys()].map((key) => key + start_with)
  }

  renderChart() {
    const survey = new Survey.Model(this.survey.data_json)

    const ratingLabels = Object.assign(
      {},
      ...survey
        .getAllQuestions()
        .filter((item) => item instanceof Survey.QuestionRatingModel)
        .map((item: any) => ({
          [item.name]: {
            intervals: this.range(item.rateMax - item.rateMin + 1, item.rateMin).map((i) => ({
              start: i - 0.5,
              end: i + 0.5,
              label: '' + i
            }))
          }
        }))
    )

    this.visPanel = new SurveyAnalytics.VisualizationPanel(survey.getAllQuestions(), this.items, {
      labelTruncateLength: 50,
      haveCommercialLicense: true,
      // set correct ration labels
      ...ratingLabels
    })
    this.visPanel.showHeader = false
    this.visPanel.render(this.$refs.container as any)
  }

  get survey(): ISurvey {
    return surveyModule.itemById(this.$router.currentRoute.params.surveyId)
  }

  get items(): { [key: string]: any }[] {
    return responseModule.itemsBySurveyId(this.survey.id!).map((item) => item.data)
  }

  @Watch('items')
  onItemsChanged() {
    if (this.visPanel) {
      this.visPanel.clear()
      this.renderChart()
    }
  }
}
