
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ISurvey, ISurveyFilter, ISurveyFilterToApply } from '@/interfaces/survey'
import surveyModule from '@/store/survey'

@Component
export default class ResponsesFilter extends Vue {
  @Prop({ required: true })
  survey!: ISurvey

  isLoading = false
  activeFilters: ISurveyFilter[] = []
  newActiveFilter: ISurveyFilter | null = null

  async mounted() {
    if (!this.filters) {
      this.isLoading = true
      await surveyModule.actionLoadFilters(this.survey)
      setTimeout(() => {
        this.isLoading = false
      }, 500)
    }
  }

  get filters(): ISurveyFilter[] | null {
    return surveyModule.filtersBySurveyId(this.survey.id!)
  }

  get autocompleteChoices(): any[] {
    if (!this.filters) return []

    const ret: any[] = []
    let lastMultilabelQuestion = ''

    this.filters.forEach((filter) => {
      if (lastMultilabelQuestion !== filter.name) {
        lastMultilabelQuestion = ''
      }

      const disabled = this.activeFilters.includes(filter)

      if (filter.type === 'multipletext' || filter.type === 'matrix') {
        if (lastMultilabelQuestion !== filter.name) {
          ret.push({
            header: filter.title
          })

          lastMultilabelQuestion = filter.name
        }

        ret.push({
          ...filter,
          value: filter,
          icon: filter.operations.includes('search') ? 'mdi-text-search' : 'mdi-filter',
          showSubName: true,
          disabled
        })
      } else {
        ret.push({
          ...filter,
          value: filter,
          icon: filter.operations.includes('search') ? 'mdi-text-search' : 'mdi-filter',
          disabled
        })
      }
    })

    return ret
  }

  addFilter() {
    if (!this.newActiveFilter) return

    this.activeFilters.push(this.newActiveFilter!)
    this.newActiveFilter = null
  }

  autocompleteSearch(item: ISurveyFilter, queryText: string) {
    return (
      `${item.title} ${item.sub_name} ${item.name}`
        .toLocaleLowerCase()
        .indexOf(queryText.toLocaleLowerCase()) > -1
    )
  }

  clearFilters() {
    this.activeFilters = []
    this.$emit('reload')
  }

  applyFilters() {
    const preparedFilters: ISurveyFilterToApply[] = []

    this.activeFilters.forEach((f: ISurveyFilter) => {
      preparedFilters.push({
        name: f.name,
        sub_name: f.sub_name,
        value: f.selectedValue || ''
      })
    })

    this.$emit('reload', preparedFilters)
  }

  removeFilter(filter: ISurveyFilter) {
    this.activeFilters = this.activeFilters.filter((f) => f !== filter)
    this.$emit('reload')
  }
}
