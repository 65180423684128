
import { Component, Vue } from 'vue-property-decorator'
import { ISurvey, IResponseFull, ISurveyFilterToApply } from '@/interfaces/survey'
import surveyModule from '@/store/survey'
import responseModule from '@/store/response'
import ResponsesFilter from '@/components/ResponsesFilter.vue'

@Component({ components: { ResponsesFilter } })
export default class ResponsesView extends Vue {
  isLoading = false

  mounted() {
    this.refresh()
  }

  get showFilters(): boolean {
    return !!this.survey && !this.isTable
  }

  get survey(): ISurvey {
    return surveyModule.itemById(this.$router.currentRoute.params.surveyId)
  }

  get items(): IResponseFull[] {
    return responseModule.itemsBySurveyId(this.survey.id!)
  }

  get isList(): boolean {
    return this.$route.name === 'admin-response-list'
  }

  get isChart(): boolean {
    return this.$route.name === 'admin-response-chart'
  }

  get isTable(): boolean {
    return this.$route.name === 'admin-response-table'
  }

  async refresh(filters: ISurveyFilterToApply[] = []) {
    this.isLoading = true
    await responseModule.actionGetResponses({ surveyId: this.survey.id!, filters })

    setTimeout(() => {
      this.isLoading = false
    }, 1000)
  }
}
