
import { Component, Vue } from 'vue-property-decorator'
import { ISurvey, IResponseFull } from '@/interfaces/survey'
import surveyModule from '@/store/survey'
import responseModule from '@/store/response'
import ResponsesFilter from '@/components/ResponsesFilter.vue'
import Confirm from '@/components/Confirm.vue'

@Component({ components: { ResponsesFilter, Confirm } })
export default class ResponseListView extends Vue {
  isLoading = false
  headers = [
    {
      text: 'Created At',
      sortable: true,
      value: 'created_at',
      align: 'left'
    },
    {
      text: 'Is completed',
      sortable: true,
      value: 'is_completed'
    },
    {
      text: 'Actions',
      value: 'id'
    }
  ]

  get survey(): ISurvey {
    return surveyModule.itemById(this.$router.currentRoute.params.surveyId)
  }

  get items(): IResponseFull[] {
    return responseModule.itemsBySurveyId(this.survey.id!)
  }

  async deleteResponse(item: IResponseFull) {
    if (await (this.$refs.confirm as Confirm).open('Are you sure?', { color: 'red' })) {
      // yes
      responseModule.actionDelete({ ...item })
    } else {
      // cancel
    }
  }
}
